/**
*
* ---------------------------------------------------------------------------
*
* Template : Blue - A One-Page HTML Portfolio/Business Template
* Author : Muhammad Morshd
* Author URI : http://morshed.im
*
* --------------------------------------------------------------------------- 
*
*/
/* =================================== */
/*	Basic Style 
/* =================================== */
body {
  background-color: #fff;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  font-size: 16px;
  color: #818181;
}

figure,
p,
address {
  margin: 0;
}

iframe {
  border: 0;
}

a {
  color: #0aa6bd;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a,
a:hover,
a:focus,
.btn:focus {
  text-decoration: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Open Sans', sans-serif;
  color: #252525;
}

p {
  font-size: 15px;
}

main > section {
  padding: 70px 0;
}

.btn {
  border-radius: 0;
  border: 0;
  position: relative;
  text-transform: uppercase;
}

.btn-blue {
  background-color: rgba(10, 166, 189, 0.75);
  box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.15) inset;
  padding: 15px 55px;
  color: #fff;
}

.btn-border {
  border: 2px solid #fff;
  color: #fff;
  padding: 12px 35px;
}

.bg-blue {
  background-color: #0aa6bd;
}

/* Sweep To Right */
.btn-effect {
  vertical-align: middle;
  box-shadow: 0 0 1px transparent;
  position: relative;
  display: inline-block;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition-property: color;
  -moz-transition-property: color;
  -ms-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-osx-font-smoothing: grayscale;
}

.btn-effect:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  -moz-transition-property: transform;
  -ms-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.btn-effect:hover,
.btn-effect:focus,
.btn-effect:active {
  color: #099db2;
}

.btn-effect:hover:before,
.btn-effect:focus:before,
.btn-effect:active:before {
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.section-title {
  margin-bottom: 80px;
}

.section-title.white {
  color: #fff;
}

.section-title h2 {
  color: #0aa6bd;
  font-size: 24px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 25px;
  padding-bottom: 20px;
  position: relative;
  text-transform: uppercase;
}

.section-title p {
  color: #444;
  font-style: italic;
  font-size: 13px;
}

.section-title.white p {
  color: #fff;
}

.section-title.white h2 {
  color: #fff;
}

.section-title h2:after {
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 2px;
  width: 72px;
  margin-left: -36px;
  background: #636363;
  content: "";
}

.section-title.white h2:after {
  background: #fff;
}

.parallax {
  background-attachment: fixed;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

/**
/*	Preloader
/* ==========================================*/
#preloader {
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

/*Battery*/
.loder-box {
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 1px;
  height: 100px;
  left: 50%;
  margin-left: -64px;
  margin-top: -50px;
  position: absolute;
  top: 50%;
  width: 128px;
}

.battery {
  width: 60px;
  height: 25px;
  top: 35%;
  border: 1px #2E2E2E solid;
  border-radius: 2px;
  position: relative;
  -webkit-animation: charge 5s linear infinite;
  -moz-animation: charge 5s linear infinite;
  animation: charge 5s linear infinite;
  margin: 0 auto;
}

.battery:after {
  background-color: #2E2E2E;
  border-radius: 0 1px 1px 0;
  content: "";
  height: 10px;
  position: absolute;
  right: -5px;
  top: 7px;
  width: 3px;
}

@-webkit-keyframes charge {
  0% {
    box-shadow: inset 0px 0px 0px #2E2E2E;
  }
  100% {
    box-shadow: inset 60px 0px 0px #2E2E2E;
  }
}

@-moz-keyframes charge {
  0% {
    box-shadow: inset 0px 0px 0px #2E2E2E;
  }
  100% {
    box-shadow: inset 60px 0px 0px #2E2E2E;
  }
}

@keyframes charge {
  0% {
    box-shadow: inset 0px 0px 0px #2E2E2E;
  }
  100% {
    box-shadow: inset 60px 0px 0px #2E2E2E;
  }
}

/**
/*	Header
/* ==========================================*/
#navigation {
  transition: all 0.8s ease 0s;
  background-color: rgba(0, 0, 0, 0.77);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  padding: 5px 0;
}

#navigation.animated-header {
  padding: 20px 0;
}

.nav-link {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #fff;
}

.nav-item {
  position: relative;
  margin: 0 10px;
}

.nav-item::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0aa6bd;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.nav-item:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

/*=================================================================
	Home Slider
==================================================================*/
#home-slider {
  position: relative;
  padding: 0;
}

.mask-overly {
  background: rgba(0, 0, 0, 0.2) none repeat scroll 0 0;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
}

.slider-1 {
  background-image: url(../img/slider/slider-1.jpg);
}

.slider-2 {
  background-image: url(../img/slider/slider-2.jpg);
}

.slider-3 {
  background-image: url(../img/slider/slider-3.jpg);
}

.sl-slider-wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.sl-slider {
  position: absolute;
  top: 0;
  left: 0;
}

/* Slide wrapper and slides */
.sl-slide,
.sl-slides-wrapper,
.sl-slide-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.sl-slide {
  z-index: 1;
}

.slide-caption {
  color: #fff;
  display: table;
  height: 100%;
  left: 0;
  min-height: 100%;
  position: absolute;
  text-align: center;
  top: 0;
  width: 60%;
  z-index: 999999;
  left: 50%;
  transform: translateX(-50%);
}

.slide-caption .caption-content {
  vertical-align: middle;
  display: table-cell;
}

.caption-content h2 {
  color: #fff;
  font-size: 50px;
  font-weight: 900;
  margin-bottom: 25px;
}

.caption-content > p {
  display: block;
  font-size: 16px;
  margin-bottom: 45px;
  text-transform: capitalize;
  margin-bottom: 65px;
  font-weight: 200;
}

/*Slider Arrow Buttons*/
#nav-arrows > a {
  border: 1px solid #fff;
  color: #fff;
  display: block;
  height: 60px;
  line-height: 76px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 60px;
  z-index: 20;
  margin-top: -30px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

#nav-arrows > a.sl-prev {
  transform: translateX(-50px);
  opacity: 0;
}

#slider:hover #nav-arrows > a.sl-prev {
  opacity: 1;
  transform: translateX(20px);
}

#nav-arrows > a.sl-next {
  right: 0;
  transform: translateX(50px);
  opacity: 0;
}

#slider:hover #nav-arrows > a.sl-next {
  opacity: 1;
  transform: translateX(-20px);
}

#nav-arrows > a.sl-next:hover,
#nav-arrows > a.sl-prev:hover {
  background-color: #0aa6bd;
  border-color: #0aa6bd;
  color: #fff;
}

/* The duplicate parts/slices */
.sl-content-slice {
  overflow: hidden;
  position: absolute;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background: #fff;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 1;
}

/* Horizontal slice */
.sl-slide-horizontal .sl-content-slice {
  width: 100%;
  height: 50%;
  left: -200px;
  -webkit-transform: translateY(0%) scale(1);
  -moz-transform: translateY(0%) scale(1);
  -o-transform: translateY(0%) scale(1);
  -ms-transform: translateY(0%) scale(1);
  transform: translateY(0%) scale(1);
}

.sl-slide-horizontal .sl-content-slice:first-child {
  top: -200px;
  padding: 200px 200px 0px 200px;
}

.sl-slide-horizontal .sl-content-slice:nth-child(2) {
  top: 50%;
  padding: 0px 200px 200px 200px;
}

/* Vertical slice */
.sl-slide-vertical .sl-content-slice {
  width: 50%;
  height: 100%;
  top: -200px;
  -webkit-transform: translateX(0%) scale(1);
  -moz-transform: translateX(0%) scale(1);
  -o-transform: translateX(0%) scale(1);
  -ms-transform: translateX(0%) scale(1);
  transform: translateX(0%) scale(1);
}

.sl-slide-vertical .sl-content-slice:first-child {
  left: -200px;
  padding: 200px 0px 200px 200px;
}

.sl-slide-vertical .sl-content-slice:nth-child(2) {
  left: 50%;
  padding: 200px 200px 200px 0px;
}

/* Content wrapper */
/* Width and height is set dynamically */
.sl-content-wrapper {
  position: absolute;
}

.sl-content {
  width: 100%;
  height: 100%;
}

/* Project laughtbox setup */
.fancybox-item.fancybox-close {
  background: url("../img/icons/close.png") no-repeat scroll 0 0 transparent;
  height: 50px;
  right: 0;
  top: 0;
  width: 50px;
}

.fancybox-next span {
  background: url("../img/right.png") no-repeat scroll center center #0aa6bd;
  height: 50px;
  width: 50px;
  right: 0;
}

.fancybox-prev span {
  background: url("../img/left.png") no-repeat scroll center center #0aa6bd;
  height: 50px;
  width: 50px;
  left: 0;
}

/*=================================================================
    Feature
==================================================================*/
.list-nav {
  padding: 0;
  margin-top: 20px;
}

.list-nav li {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  padding-left: 30px;
  margin-bottom: 10px;
  position: relative;
}

.list-nav li i {
  position: absolute;
  left: 0;
  top: 4px;
  font-size: 28px;
  color: #1fb5f6;
}

/*=================================================================
    Prototype
==================================================================*/
#prototype {
  background: #f1f1f1;
}

#prototype .block img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

#prototype .block p {
  margin-top: 10px;
}

#prototype .block .btn {
  margin-top: 20px;
}

/*=================================================================
	Services
==================================================================*/
.service-icon {
  border: 3px solid transparent;
  display: inline-block;
  line-height: 40px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.service-item {
  margin-bottom: 60px;
  padding: 0 14px;
}

.service-icon i {
  font-size: 60px;
  color: #0aa6bd;
}

.service-item h3 {
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 20px;
  color: #3c3d41;
  text-transform: uppercase;
}

.service-item p {
  color: #7e848e;
  font-weight: 200;
}

/*=================================================================
    Video Section
==================================================================*/
.video-bg {
  background: transparent url("../img/video-bg.jpg") no-repeat scroll center center/cover;
  color: #fff;
  position: relative;
  text-align: center;
  z-index: 1;
}

.modal-dialog {
  margin: 100px auto;
}

.modal-header {
  border: none;
}

.video-bg .section-title p {
  color: #fff;
}

.video-bg .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.video-popup-button {
  background-color: #0aa6bd;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 90px;
  height: 150px;
  line-height: 150px;
  margin-bottom: 80px;
  width: 150px;
  position: relative;
}

.video-popup-button i {
  margin-left: 15px;
}

.video-popup-button:hover,
.video-popup-button:focus {
  background-color: transparent;
  color: #fff;
}

.video-popup-button:before {
  border: 2px solid #fff;
  border-radius: 50%;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.3s ease 0s;
}

.video-popup-button:hover:before {
  opacity: 1;
}

/*=================================================================
	Portfolio
==================================================================*/
.project-wrapper {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.project-wrapper .row {
  margin: 0;
}

.project-wrapper [class^="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.portfolio-filter {
  font-size: 0;
  list-style: outside none none;
  margin: 0 0 40px;
  padding: 0;
}

.portfolio-filter li {
  display: inline-block;
}

.portfolio-filter li a {
  color: #777;
  display: block;
  font-size: 14px;
  padding: 0 20px;
  position: relative;
}

.portfolio-filter li a.active {
  color: #0aa6bd;
}

.portfolio-filter li a::after {
  bottom: auto;
  content: "/";
  position: absolute;
  right: 0;
  top: auto;
}

.portfolio-filter li:last-child a::after {
  content: none;
}

.portfolio-item {
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.portfolio-item img {
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.portfolio-item:hover img {
  -webkit-transform: translateY(-105px);
  -ms-transform: translateY(-105px);
  -o-transform: translateY(-105px);
  transform: translateY(-105px);
}

figcaption.mask {
  background-color: #f1f1f1;
  bottom: 0;
  color: #333;
  padding: 25px;
  position: absolute;
  width: 100%;
  text-align: left;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.portfolio-item:hover figcaption.mask {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

figcaption.mask h3 {
  margin-top: 0;
  color: #333;
  font-size: 18px;
  margin-bottom: 15px;
  opacity: 0;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 0.4s ease 0.1s;
  -moz-transition: all 0.4s ease 0.1s;
  -o-transition: all 0.4s ease 0.1s;
  transition: all 0.4s ease 0.1s;
}

figcaption.mask p {
  line-height: 1.3;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
  -webkit-transition: all 0.4s ease 0.3s;
  -moz-transition: all 0.4s ease 0.3s;
  -o-transition: all 0.4s ease 0.3s;
  transition: all 0.4s ease 0.3s;
}

.portfolio-item:hover figcaption.mask h3,
.portfolio-item:hover figcaption.mask p {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

ul.external {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: -47px;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

ul.external li {
  display: inline-block;
}

ul.external li a {
  background-color: rgba(255, 255, 255, 0.9);
  color: #818181;
  display: block;
  padding: 10px 18px 13px;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

ul.external li a:hover {
  background-color: #0aa6bd;
  color: #fff;
}

.portfolio-item:hover ul.external {
  top: 0;
}

.fancybox-skin {
  border-radius: 0;
}

.fancybox-title.fancybox-title-inside-wrap {
  padding: 15px;
}

.fancybox-title h3 {
  margin: 0 0 15px;
}

.fancybox-title p {
  color: #818181;
  font-size: 16px;
  line-height: 22px;
}

.fancybox-title-inside-wrap {
  padding-top: 0;
}

/*=================================================================
	Testimonials
==================================================================*/
#testimonials {
  background-image: url(../img/parallax/testimonial.jpg);
  /*padding: 0;*/
  color: #fff;
  position: relative;
}

#testimonials:before {
  background-color: rgba(10, 166, 189, 0.75);
  padding: 70px 0 40px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  content: '';
}

.testimonial-item {
  margin: 0 auto;
  padding-bottom: 50px;
  width: 64%;
}

.testimonial-item img {
  border: 3px solid #fff;
  border-radius: 50%;
  display: inline-block;
  height: auto;
  max-width: 100px;
}

.testimonial-item > div {
  line-height: 30px;
  position: relative;
}

.testimonial-item > div:before {
  background-image: url("../img/icons/quotes.png");
  background-repeat: no-repeat;
  bottom: 127px;
  height: 33px;
  left: -35px;
  position: absolute;
  width: 45px;
  content: "";
}

.testimonial-item > div:after {
  background-image: url("../img/icons/quotes.png");
  background-position: -58px 0;
  background-repeat: no-repeat;
  bottom: -50px;
  height: 33px;
  position: absolute;
  right: 0;
  width: 45px;
}

.testimonial-item > div > span {
  display: inline-block;
  font-weight: 700;
  margin: 40px 0 30px;
  text-transform: uppercase;
}

#testimonials .owl-buttons {
  border: 2px solid #fff;
  display: inline-block;
  padding: 0;
  margin-left: 50%;
  transform: translateX(-50%);
}

.owl-controls .owl-page,
.owl-controls .owl-buttons div {
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
  padding: 10px;
}

#testimonials .owl-prev:hover,
#testimonials .owl-next:hover {
  color: #fff;
}

.price-table {
  border: 1px solid #e3e3e3;
}

.price-table.featured {
  border-color: #e3e3e3;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.19);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.19);
}

.price-table > span {
  color: #444;
  display: block;
  font-size: 24px;
  padding: 30px 0;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
}

.price-table .value {
  background-color: #f8f8f8;
  color: #727272;
  padding: 20px 0;
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -ms-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
}

.price-table.featured .value {
  background-color: #0aa6bd;
  color: #fff;
}

.price-table .value span {
  display: inline-block;
}

.price-table .value span:first-child {
  font-size: 32px;
  line-height: 32px;
}

.price-table .value span:nth-child(2) {
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 10px;
}

.price-table .value span:last-child {
  font-size: 16px;
}

.price-table ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.price-table ul li {
  border-top: 1px solid #e3e3e3;
  display: block;
  padding: 15px 0;
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -ms-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
}

.price-table ul li a {
  display: block;
  text-transform: uppercase;
}

.price-table.featured ul li:last-child,
.price-table ul li:last-child:hover {
  background-color: #0aa6bd;
}

.price-table.featured ul li:last-child a,
.price-table ul li:last-child:hover a {
  color: #fff;
}

/*=================================================================
	Price
==================================================================*/
#social {
  background-image: url(../img/parallax/testimonial.jpg);
  padding: 0;
}

#social .overlay {
  background: url("../img/slide_bg.png") repeat scroll 0 0 transparent;
  padding: 100px 0 120px;
}

.social-button {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.social-button li {
  display: inline-block;
  margin: 0 20px;
}

.social-button li a {
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  display: block;
  height: 90px;
  line-height: 96px;
  width: 90px;
  font-size: 40px;
  line-height: 90px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}

.social-button li a:hover {
  border: 2px solid #0aa6bd;
  background: #0aa6bd;
  color: #fff;
}

/*=================================================================
	Contact
==================================================================*/
.input-field {
  margin-bottom: 10px;
}

.form-control {
  border: 1px solid #ececec;
  border-radius: 0;
  box-shadow: none;
  color: #818181;
  font-size: 14px;
  height: 40px;
}

.form-control:focus {
  box-shadow: none;
  border-color: #0aa6bd;
}

textarea.form-control {
  width: 100%;
  height: 165px;
}

.input-field label.error {
  color: red;
  display: block;
  font-size: 13px;
  font-weight: 400;
}

#submit:hover {
  color: #fff;
}

#submit:before {
  background-color: rgba(10, 166, 189, 0.75);
}

#submit.btn-effect:after {
  background: #2E2E2E;
}

.contact-details h3 {
  border-bottom: 1px solid #dedede;
  font-weight: 600;
  margin-bottom: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 0;
  color: #666;
}

.contact-details p {
  line-height: 30px;
}

.contact-details p i {
  margin-right: 15px;
  font-size: 25px;
  color: #000;
}

.contact-details span {
  display: block;
  margin-left: 24px;
}

/*============================================================
	Google Maps
==============================================================*/
#google-map {
  padding: 0;
  height: 400px;
}

#map-canvas {
  width: 100%;
  height: 300px;
}

/*============================================================
	Footer
==============================================================*/
#footer {
  background-color: #2E2E2E;
  padding: 70px 0;
  color: #fff;
}

.footer-content {
  width: 390px;
  margin: 0 auto;
}

.footer-content > div {
  margin-bottom: 40px;
}

.footer-content > div > p:first-child {
  margin-bottom: 15px;
  text-transform: uppercase;
}

.subscribe-form {
  position: relative;
}

.subscribe.form-control {
  background-color: transparent;
  border: 1px solid #7f7f7f;
}

.subscribe.form-control:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.submit-icon {
  background-color: #7f7f7f;
  border: 0 none;
  border-radius: 0;
  color: #c1c1c1;
  padding: 8px 20px;
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 0;
}

.submit-icon .fa-paper-plane {
  position: relative;
  top: 0;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 0.3s ease 0.2s;
  -moz-transition: all 0.3s ease 0.2s;
  -ms-transition: all 0.3s ease 0.2s;
  -o-transition: all 0.3s ease 0.2s;
  transition: all 0.3s ease 0.2s;
}

.submit-icon:hover .fa-paper-plane {
  position: relative;
  top: -37px;
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -ms-transform: translateX(30px);
  -o-transform: translateX(30px);
  transform: translateX(30px);
}

.footer-content .footer-social {
  margin: 40px 0 35px;
}

.footer-social ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.footer-social ul li {
  display: inline-block;
  margin: 0 10px;
}

.footer-social ul li a {
  color: #7f7f7f;
  display: block;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}

.footer-social ul li a:hover {
  color: #0aa6bd;
}

.footer-content > p {
  color: #ababab;
  font-size: 12px;
}

/*============================================================ 
	Responsive Styles
 ============================================================*/
/*============================================================
	For Small Desktop
==============================================================*/
@media (min-width: 980px) and (max-width: 1150px) {
  /*about*/
  #about .welcome-block img {
    margin-bottom: 30px;
  }
}

/*============================================================
	Tablet (Portrait) Design for a width of 768px
==============================================================*/
@media (min-width: 768px) and (max-width: 979px) {
  /* home slider  */
  .caption-content h2 {
    font-size: 40px;
  }
  .caption-content p {
    font-size: 25px;
  }
  .caption-content strong {
    font-size: 45px;
  }
  /* about */
  .recent-works {
    margin-bottom: 50px;
  }
  .service-item {
    margin-bottom: 50px;
  }
  /* testimonial */
  .testimonial-item {
    width: 100%;
  }
  .testimonial-item {
    width: 80%;
  }
  .testimonial-item > div:after {
    bottom: -35px;
  }
  /* price */
  .price-table {
    margin-bottom: 50px;
  }
  /* contact form */
  .contact-form {
    margin-bottom: 50px;
  }
}

/*============================================================
	Mobile (Portrait) Design for a width of 320px
==============================================================*/
@media only screen and (max-width: 767px) {
  body {
    font-size: 14px;
  }
  .parallax {
    background-position: centet tip !important;
  }
  .section-title h2 {
    font-size: 25px;
  }
  /*navigation*/
  .navbar-inverse .navbar-toggle {
    border-color: #fff;
  }
  .navbar-inverse .navbar-toggle:hover,
  .navbar-inverse .navbar-toggle:focus {
    background-color: transparent;
  }
  /* slider */
  .caption-content h2 {
    font-size: 18px;
  }
  .caption-content > span {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .caption-content p {
    font-size: 16px;
    margin-bottom: 30px;
  }
  .caption-content strong {
    font-size: 22px;
  }
  /* about */
  .recent-works {
    margin-bottom: 85px;
  }
  #about h3 {
    font-size: 18px;
    margin: 0 0 35px !important;
  }
  #about .owl-buttons {
    margin-top: 20px;
  }
  #about .message-body {
    margin-bottom: 45px;
  }
  #about .welcome-block img {
    margin: 0 25px 15px 0;
  }
  /* service */
  .service-item {
    width: 100%;
  }
  /*portfolio*/
  /* lightbox */
  .fancybox-title h3 {
    font-size: 20px;
  }
  .fancybox-title p {
    font-size: 14px;
  }
  /* testimonial */
  .testimonial-item {
    width: 95%;
  }
  .testimonial-item > div:before,
  .testimonial-item > div:after {
    background-image: none;
  }
  .testimonial-item > div > span {
    margin: 30px 0 20px;
  }
  /* price */
  .price-table {
    margin-bottom: 50px;
  }
  /* follow us */
  .social-button li {
    margin: 0 10px;
  }
  .social-button li a {
    height: 65px;
    line-height: 71px;
    width: 65px;
  }
  /* contact form */
  .contact-form {
    margin-bottom: 50px;
  }
  /* footer */
  .footer-content {
    width: 100%;
  }
  .footer-social ul li {
    margin: 0 7px;
  }
}

.success-ms {
  background-color: #6cb670;
  border-radius: 4px;
  color: #fff;
  display: none;
  font-size: 13px;
  padding: 10px;
  margin-bottom: 10px;
}

.error-ms {
  display: none;
  padding: 10px;
  color: #D8000C;
  border-radius: 4px;
  font-size: 13px;
  background-color: #FFBABA;
  margin-bottom: 10px;
}

/*============================================================
	Mobile (Landscape) Design for a width of 480px
==============================================================*/
@media only screen and (min-width: 480px) and (max-width: 767px) {
  /* home slider */
  .caption-content h2 {
    font-size: 35px;
  }
  .caption-content p {
    font-size: 25px;
  }
  .caption-content strong {
    font-size: 35px;
  }
  /*about*/
  #about .welcome-block h3 {
    margin: 0 0 35px;
  }
  /* service */
  .service-item {
    margin: 0 auto 50px;
    width: 55%;
  }
  /* portfolio */
  /* testimonial */
  .testimonial-item {
    width: 80%;
  }
  .testimonial-item > div:before {
    bottom: 160px;
  }
  /* social */
  .social-button li a {
    height: 90px;
    line-height: 96px;
    width: 90px;
  }
  /* price */
  .price-table {
    margin-bottom: 50px;
  }
  /* contact form */
  .contact-form {
    margin-bottom: 50px;
  }
  /* footer */
  .footer-content {
    width: 380px;
  }
}

/*Bottom Footer*/
#footer p {
  color: #6f6f6f;
}

#footer form {
  margin-top: 20px;
}

#footer ul {
  padding-left: 0;
}

#footer ul li {
  list-style: none;
  margin-bottom: 5px;
}

#footer ul li a {
  color: #6f6f6f;
  display: block;
}

#footer ul li a:hover {
  color: #099db2;
}

#footer h4 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 30px;
  letter-spacing: 2px;
  font-size: 16px;
}

#footer-bottom {
  background: #2A2A2A;
  padding: 20px 0;
}

#footer-bottom p {
  font-size: 13px;
}
